import HeroSlider, { Slide, MenuNav } from "hero-slider";

const slide1 = "https://trailblazer.caltech.edu/images/spacecraft3.jpg";
const slide2 = "https://trailblazer.caltech.edu/images/LTMShipped.jpg"
const slide3 = "https://trailblazer.caltech.edu/images/news/HVM3_ATLO_2.jpg";
const slide4 = "https://trailblazer.caltech.edu/images/lunarWaterCycleInfographic.jpg";
const slide5 = "https://trailblazer.caltech.edu/images/news/HVM3_ATLO_1.jpg";

export default function BasicSlider() {
  return (
    <HeroSlider
      height={"calc((var(--vh, 1vh) * 100) - 65px)"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 1200,
        slidingDelay: 200,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) =>
          console.debug("onAfterSliding(nextSlide): ", nextSlide)
      }}
    >


      <Slide
        label="Lunar Trailblazer On Track for Launch Readiness in Q4 2024"
        background={{
          backgroundImageSrc: slide1
        }}
      >
        <a href="https://trailblazer.caltech.edu/objectives.html">
          <div class="headline-overlay">
            <h2 style={{ color: "white" }}>
              Lunar Trailblazer On Track for Launch Readiness in Q4 2024
            </h2>
            <p style={{ color: "white" }}>
              Lunar Trailblazer achieves critical advancements for science as a
              lower-budget mission, and will launch as a secondary payload on IM-2, 
              the second lunar lander mission by Intuitive Machines.
            </p>
          </div>
        </a>
        <a href="https://trailblazer.caltech.edu/objectives.html">
          <div class="overlay-hyperlink"></div>
        </a>
      </Slide>

      <Slide
        label="LTM Instrument Arrives at Lockheed Martin"
        background={{
          backgroundImageSrc: slide2
        }}
      >
        <a href="https://trailblazer.caltech.edu/news/LTMShipped.html">
          <div class="headline-overlay">
            <h2 style={{ color: "white" }}>
              LTM Instrument Arrives at Lockheed Martin
            </h2>
            <p style={{ color: "white" }}>
              University of Oxford's LTM instrument has been shipped to Lockheed 
              Martin Space in Colorado for integration with the Lunar 
              Trailblazer spacecraft.
            </p>
          </div>
        </a>
        <a href="https://trailblazer.caltech.edu/news/LTMShipped.html">
          <div class="overlay-hyperlink"></div>
        </a>
      </Slide>
      
      <Slide
        label="HVM3 Instrument Integrated with Lunar Trailblazer"
        background={{
          backgroundImageSrc: slide3
        }}
      >
        <a href="https://trailblazer.caltech.edu/news/HVM3Delivery.html">
          <div class="headline-overlay">
            <h2 style={{ color: "white" }}>
              HVM<sup>3</sup> Instrument Integrated with Lunar Trailblazer
            </h2>
            <p style={{ color: "white" }}>
              Lunar Trailblazer's HVM<sup>3</sup> instrument, built at NASA's
              Jet Propulsion Laboratory in Southern California, has been
              delivered and integrated with the satellite at Lockheed Martin
              Space in Colorado.
            </p>
          </div>
        </a>
        <a href="https://trailblazer.caltech.edu/news/HVM3Delivery.html">
          <div class="overlay-hyperlink"></div>
        </a>
      </Slide>

      <Slide
        label="Lunar Geologic Settings"
        background={{
          backgroundImageSrc: slide4
        }}
      >
        <a href="https://trailblazer.caltech.edu/lunarWater.html">
          <div class="headline-overlay">
            <h2 style={{ color: "white" }}>What is Lunar Water?</h2>
            <p style={{ color: "white" }}>
            The surface of the Moon is hydrated—but not from drinking eight 
            glasses of water a day. Here’s a quick guide to the different 
            forms of water that we may find on the Moon.
            </p>
          </div>
        </a>
        <a href="https://trailblazer.caltech.edu/lunarWater.html">
          <div class="overlay-hyperlink"></div>
        </a>
      </Slide>

      <Slide
        label="Lunar Trailblazer Begins ATLO at Lockheed Martin Space"
        background={{
          backgroundImageSrc: slide5
        }}
      >
        <a href="https://trailblazer.caltech.edu/news/ATLOStart.html">
          <div class="headline-overlay">
            <h2 style={{ color: "white" }}>
              Lunar Trailblazer Begins ATLO (Assembly, Test, and Launch
              Operations) at Lockheed Martin Space
            </h2>
            <p style={{ color: "white" }}>
              Lunar Trailblazer entered Phase D, commonly referred to as ATLO
              (Assembly, Test, and Launch Operations), at the end of October,
              and work has been underway at Lockheed Martin.
            </p>
          </div>
        </a>
        <a href="https://trailblazer.caltech.edu/news/ATLOStart.html">
          <div class="overlay-hyperlink"></div>
        </a>
      </Slide>

      <MenuNav />
    </HeroSlider>
  );
}
